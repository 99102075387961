export enum ReferenceIdEnum {
  'favorite-apps' = 'favorite-apps',
  'home' = 'home-tab',
  'customers' = 'customers-tab',
  'sellers' = 'sellers-tab',
  'brands' = 'brands-tab',
  'promotions' = 'promotions-tab',
  'help-item' = 'help-item',
  'orders-posts' = 'orders-posts-tab',
  'abandoned-carts' = 'abandoned-carts-tab',
  'dashboard' = 'dashboard-tab',
  'orders' = 'orders-tab',
  'cashiers' = 'cashiers-tab',
  'collects-control' = 'collects-control',
  'cashiers-reports' = 'cashiers-reports',
  'devices' = 'devices-tab',
  'companies' = 'companies-tab',
  'taxation-rules' = 'taxation-rules-tab',
  'categories' = 'categories-tab',
  'catalogs' = 'catalogs-tab',
  'product-environments' = 'product-environments-tab',
  'stock-locals' = 'stock-locals-tab',
  'products' = 'products-tab',
  'events' = 'events-tab',
  'stock-adjustment' = 'stock-adjustment-tab',
  'stock-monitoring' = 'stock-monitoring-tab',
  'marketplaces-management' = 'marketplaces-management',
  'stock-transfer-group' = 'stock-transfer-group-tab',
  'user-groups' = 'user-groups-tab',
  'users' = 'users-tab',
  'integrations' = 'integrations-tab',
  'my-account-item' = 'my-account-item',
  'notifications-item' = 'notifications-item',
  'courses-item' = 'courses-item',
  'orders-reports' = 'orders-reports-tab',
  'products-reports' = 'products-reports-tab',
  'stock-reports' = 'stock-reports-tab',
  'file-transmissions' = 'file-transmissions-tab',
  'loans' = 'loans',
  'last-transactions' = 'last-transactions',
  'recharges' = 'recharges',
  'bill-payments' = 'bill-payments',
  'products-batch-update' = 'products-batch-update',
  'products-price-list' = 'products-price-list',
  'permissions-granted' = 'permissions-granted',
  'products-import' = 'products-import',
  'custom-fields' = 'custom-fields',
}
