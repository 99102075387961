/* eslint-disable camelcase */
import {
  createContext,
  useContext,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

import { Map } from 'leaflet';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { ILatLng, IMapLayer } from '~/shared/interfaces/IAppOwnDeliveryConfig';
import { GeoDrawTypeEnum } from '~/shared/enums/GeoDrawTypeEnum';

interface IFormattedMapLayers {
  coordsGroup: ILatLng[];
  drawType: GeoDrawTypeEnum;
  circleRadius?: number;
}

interface IMapContextData {
  onCreated(e): void;
  onEdited(e): void;
  onDeleted(e): void;
  mapLayers: IMapLayer[];
  setMapLayers: Dispatch<SetStateAction<IMapLayer[]>>;
  formatMapLayers(formatMapLayers: IMapLayer[]): IFormattedMapLayers;
  clearMapLayers(): void;
  setMap: Dispatch<SetStateAction<Map>>;
  flyToCoords(coords: ILatLng): void;
  setDrawType: Dispatch<SetStateAction<GeoDrawTypeEnum>>;
}

const MapContext = createContext({} as IMapContextData);

const MapProvider: FCWithChildren = ({ children }) => {
  const [map, setMap] = useState<Map>(null);
  const [mapLayers, setMapLayers] = useState<IMapLayer[]>([]);
  const [drawType, setDrawType] = useState(null);

  const onCreatedPolygon = useCallback((layer) => {
    const { _leaflet_id } = layer;

    setMapLayers((prevState) => [
      ...prevState,
      {
        id: _leaflet_id,
        type: GeoDrawTypeEnum.polygon,
        latlngs: layer.getLatLngs()[0],
      },
    ]);
  }, []);

  const onCreatedCircle = useCallback((layer) => {
    const { _leaflet_id, _latlng, _mRadius } = layer;

    setMapLayers((prevState) => [
      ...prevState,
      {
        id: _leaflet_id,
        type: GeoDrawTypeEnum.circle,
        latlngs: [{ lat: _latlng.lat, lng: _latlng.lng }],
        circleRadius: _mRadius,
      },
    ]);
  }, []);

  const onCreated = useCallback(
    (e) => {
      if (e) {
        const { layerType, layer } = e;

        if (layerType === GeoDrawTypeEnum.polygon) onCreatedPolygon(layer);
        if (layerType === GeoDrawTypeEnum.circle) onCreatedCircle(layer);
      }
    },
    [onCreatedCircle, onCreatedPolygon]
  );

  const onEditedPolygon = useCallback((_layers) => {
    Object.values(_layers).forEach(({ editing }) => {
      setMapLayers((prevState) => {
        const newMapLayers = [...prevState];

        newMapLayers[0] = {
          ...newMapLayers[0],
          latlngs: editing.latlngs[0][0],
        };

        return newMapLayers;
      });
    });
  }, []);

  const onEditedCircle = useCallback((_layers) => {
    Object.values(_layers).forEach(({ _latlng, _mRadius }) => {
      setMapLayers((prevState) => {
        const newMapLayers = [...prevState];

        newMapLayers[0] = {
          ...newMapLayers[0],
          latlngs: [_latlng],
          circleRadius: _mRadius,
        };

        return newMapLayers;
      });
    });
  }, []);

  const onEdited = useCallback(
    (e) => {
      if (e) {
        const {
          layers: { _layers },
        } = e;

        if (drawType) {
          if (drawType === GeoDrawTypeEnum.polygon) onEditedPolygon(_layers);
          if (drawType === GeoDrawTypeEnum.circle) onEditedCircle(_layers);
        }
      }
    },
    [drawType, onEditedCircle, onEditedPolygon]
  );

  const onDeleted = useCallback((e) => {
    if (e) {
      const {
        layers: { _layers },
      } = e;

      Object.values(_layers).forEach(({ _leaflet_id }) => {
        setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
      });
    }
  }, []);

  const clearMapLayers = useCallback((): void => {
    setMapLayers([]);
  }, []);

  function formatMapLayers(
    mapLayersToFormat: IMapLayer[]
  ): IFormattedMapLayers {
    const newMapLayers = mapLayersToFormat.map((mapLayer) => {
      return mapLayer.latlngs;
    });

    return {
      coordsGroup: newMapLayers[0],
      drawType: mapLayersToFormat[0]?.type,
      circleRadius: mapLayersToFormat[0]?.circleRadius,
    };
  }

  function flyToCoords(coords: ILatLng): void {
    if (map && coords) map.flyTo(coords);
  }

  return (
    <MapContext.Provider
      value={{
        onCreated,
        onEdited,
        onDeleted,
        mapLayers,
        setMapLayers,
        formatMapLayers,
        clearMapLayers,
        setMap,
        flyToCoords,
        setDrawType,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

const useMap = (): IMapContextData => {
  return useContext(MapContext);
};

export { MapProvider, useMap };
